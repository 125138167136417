'use client'

import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useTheme, alpha } from '@mui/material/styles'
import { useScrollTrigger, AppBar, Toolbar, Grid, Container, Button, IconButton, Typography } from '@mui/material'
import { Facebook as FacebookIcon, YouTube as YouTubeIcon } from '@mui/icons-material'

// Types
import type { AppBarProps } from '@/types/components/organisms/appbar'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'

// Components
const HideOnScroll = dynamic(() => import('@/components/theme/hide-on-scroll'))
const MenuMolecule = dynamic(() => import('@/components/molecules/menu/desktop'))
const ModeMolecule = dynamic(() => import('@/components/molecules/mode'))
const LogoAtom = dynamic(() => import('@/components/atoms/logo'))
const SearchIconAtom = dynamic(() => import('@/components/atoms/icons/search'))

const AppBarOrganism = (props: AppBarProps) => {
	// Props
	const { menus } = props

	// Variables
	const { t } = useTranslation()
	const theme = useTheme()
	const trigger = useScrollTrigger()

	return (
		<AppBar
			sx={{
				display: { xs: 'none', md: 'block' },
				backgroundColor: theme.palette.background.paper,
				'& .MuiToolbar-root': { p: 0 },
				...(theme.palette.mode == 'dark'
					? { outline: `1px solid ${alpha(theme.palette.primary.main, 0.2)}` }
					: { boxShadow: `0 12px 40px ${alpha(theme.palette.common.black, 0.04)}` })
			}}
		>
			<Toolbar>
				<Grid container flexDirection="column">
					<HideOnScroll onlyDesktop={true}>
						<Grid
							item
							xs={12}
							py={0.85}
							fontSize={14}
							color="#fdfefd"
							zIndex={2}
							sx={{
								backgroundColor: theme.palette.common.black,
								'& .MuiButton-root': {
									minWidth: 20,
									color: '#fdfefd'
								},
								'& .MuiIconButton-root': { color: '#fdfefd' },
								'& .MuiIconButton-colorPrimary': { color: theme.palette.primary.main }
							}}
						>
							<Container>
								<Grid container justifyContent="space-between" alignItems="center">
									<Grid item mr={2}>
										{t('common:app.slogan')}
									</Grid>

									<Grid item fontWeight={800}>
										<Grid container justifyContent="start" alignItems="center">
											<Typography
												fontSize={14}
												mr={1}
												sx={{
													'& a': {
														color: theme.palette.common.white,
														transition: 'all .3s ease',
														'&:hover': { color: theme.palette.primary.light }
													}
												}}
											>
												{t('common:title.mainOffice')}: <Link href="tel:+18675898100">+1 (867) 589.8100</Link>
											</Typography>

											<Link href="https://fb.me/DelineGotineGovernment" target="_blank">
												<Button size="small" aria-label="facebook" sx={{ mr: 0.5 }}>
													<FacebookIcon />
												</Button>
											</Link>

											<Link href="https://youtube.com/@delinegotinegovernment5894/videos" target="_blank">
												<Button size="small" aria-label="youtube" sx={{ mr: 1 }}>
													<YouTubeIcon />
												</Button>
											</Link>

											<ModeMolecule text={false} />
										</Grid>
									</Grid>
								</Grid>
							</Container>
						</Grid>
					</HideOnScroll>

					<Grid item xs={12} mt={trigger ? -6 : 0} py={0.8} zIndex={1} sx={{ transition: 'margin-top 0.25s cubic-bezier(0, 0, 0.2, 1) 0s' }}>
						<Container>
							<Grid container justifyContent="start" alignItems="center">
								<Grid item mr={2}>
									<Link href="/">
										<Button
											sx={{
												'& img': {
													width: 'auto',
													height: { xs: 48, lg: 84 }
												}
											}}
										>
											<LogoAtom />
										</Button>
									</Link>
								</Grid>

								<MenuMolecule items={menus} />

								<Grid item>
									<Link href="/search">
										<IconButton sx={{ color: theme.palette.mode == 'dark' ? theme.palette.common.white : '#343434' }}>
											<SearchIconAtom />
										</IconButton>
									</Link>
								</Grid>
							</Grid>
						</Container>
					</Grid>
				</Grid>
			</Toolbar>
		</AppBar>
	)
}

export default AppBarOrganism
