// Configurations
import Config from '@/configs'

// Types
import type { LocaleProps } from '@/types/configs/locale'

const LocaleConfig: LocaleProps = {
	default: 'en',
	list: ['en', 'es', 'ar'],
	ns: ['common', 'links', 'form'],
	cookieName: `${Config.shortName.toLocaleLowerCase()}-i18next`
}

export default LocaleConfig
