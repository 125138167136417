'use client'

import { useState } from 'react'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useTheme } from '@mui/material/styles'
import { AppBar, Toolbar, Grid, Container, IconButton } from '@mui/material'
import { Menu as MenuIcon } from '@mui/icons-material'

// Types
import type { AppBarProps } from '@/types/components/organisms/appbar'

// Components
const DrawerOrganism = dynamic(() => import('@/components/organisms/drawer'))
const LogoButtonMolecule = dynamic(() => import('@/components/molecules/logo-button'))
const SearchIconAtom = dynamic(() => import('@/components/atoms/icons/search'))

const AppBarOrganism = (props: AppBarProps) => {
	// Props
	const { menus } = props

	// Variables
	const theme = useTheme()
	const [open, setOpen] = useState<boolean>(false)

	return (
		<AppBar
			sx={{
				display: { xs: 'block', md: 'none' },
				backgroundColor: theme.palette.common.black,
				'& .MuiToolbar-root': { p: 0 }
			}}
		>
			<Toolbar>
				<Grid container>
					<Grid item xs={12} px={1.25} position="relative" color="#fdfefd" zIndex={theme.zIndex.appBar}>
						<Container maxWidth="xs">
							<Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ width: 1 }}>
								<Grid
									item
									xs={6}
									height={40}
									sx={{
										'& img': {
											width: 'auto',
											height: 40
										}
									}}
								>
									<LogoButtonMolecule />
								</Grid>

								<Grid item>
									<Link href="/search">
										<IconButton sx={{ color: '#fdfefd' }}>
											<SearchIconAtom />
										</IconButton>
									</Link>

									<IconButton aria-label="toggle menu visibility" onClick={() => setOpen(!open)} sx={{ color: '#fdfefd' }}>
										<MenuIcon />
									</IconButton>
								</Grid>
							</Grid>
						</Container>

						<DrawerOrganism open={open} setOpen={setOpen} items={menus} />
					</Grid>
				</Grid>
			</Toolbar>
		</AppBar>
	)
}

export default AppBarOrganism
