'use client'

import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useTheme, alpha } from '@mui/material/styles'
import { Grid, Container, Button, Typography, List, ListItemButton, ListItemText, IconButton, Box } from '@mui/material'
import { Facebook as FacebookIcon, YouTube as YouTubeIcon } from '@mui/icons-material'

// Types
import type { FooterProps } from '@/types/components/organisms/footer'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'

// Fonts
import PoppinsFont from '@/styles/fonts/poppins'

// Components
const ScrollTopAtom = dynamic(() => import('@/components/atoms/scroll-top'))
const LogoAtom = dynamic(() => import('@/components/atoms/logo'))

const FooterOrganism = (props: FooterProps) => {
	// Props
	const { quickLinks, departmentsLinks } = props

	// Variables
	const { t } = useTranslation()

	// Theme
	const theme = useTheme()

	return (
		<Grid
			position="relative"
			py={8}
			color="#fdfefd"
			sx={{
				backgroundColor: theme.palette.common.black,
				outline: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`
			}}
		>
			<ScrollTopAtom />

			<Container>
				<Grid container mb={4}>
					<Grid item mr={2}>
						<Link href="/">
							<Button
								sx={{
									'& img': {
										width: 'auto',
										height: 84
									}
								}}
							>
								<LogoAtom mode="dark" />
							</Button>
						</Link>
					</Grid>
				</Grid>

				<Grid
					container
					justifyContent="space-between"
					mb={2}
					pb={5}
					borderBottom="1px solid #555"
					spacing={1}
					sx={{
						'& div.MuiTypography-root': {
							fontFamily: PoppinsFont.style.fontFamily,
							fontSize: 18,
							fontWeight: 700,
							textTransform: 'uppercase',
							color: theme.palette.primary.light
						},
						'& a, & .MuiButtonBase-root': {
							color: theme.palette.common.white,
							transition: 'all .3s ease',
							'&:hover': { color: theme.palette.primary.light }
						}
					}}
				>
					<Grid item xs={12} sm={6} md={4} lg={3} mb={4}>
						<Typography variant="h3" component="div" mb={3}>
							{t('common:title.contact')}
						</Typography>

						<Grid>
							<Box
								component="address"
								sx={{
									display: 'inline-block',
									fontStyle: 'normal'
								}}
							>
								<Typography component="p">{t('common:content.deline')}</Typography>
								<Typography component="p">{t('common:content.postalCode')}</Typography>
							</Box>

							<Typography my={0.5}>
								{t('common:title.telephone')}: <Link href="tel:+18675898100">(867) 589.8100</Link>
							</Typography>

							<Typography my={0.5}>
								{t('common:title.fax')}: <Link href="fax:+18675898100">(867) 589.8101</Link>
							</Typography>

							<Typography my={0.5}>
								{t('common:title.email')}: <Link href="mailto:receptionist@gov.deline.ca">receptionist@gov.deline.ca</Link>
							</Typography>
						</Grid>

						<List component="nav" sx={{ mt: 3, p: 0 }}>
							<Link href="https://fb.me/DelineGotineGovernment" target="_blank">
								<IconButton size="small" aria-label="facebook" sx={{ mr: 1 }}>
									<FacebookIcon />
								</IconButton>
							</Link>

							<Link href="https://youtube.com/@delinegotinegovernment5894/videos" target="_blank">
								<IconButton size="small" aria-label="youtube" sx={{ mr: 1 }}>
									<YouTubeIcon />
								</IconButton>
							</Link>
						</List>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3} mb={4}>
						<Typography variant="h3" component="div" mb={3}>
							{t('common:title.quick')}
						</Typography>

						<Grid fontSize="0.8125em">
							<List component="nav" sx={{ p: 0 }}>
								{quickLinks.map((item, index) => {
									return (
										<Link key={index} href={item.link}>
											<ListItemButton sx={{ p: 0, mb: 0.5 }}>
												<ListItemText>{t(item.title)}</ListItemText>
											</ListItemButton>
										</Link>
									)
								})}
							</List>
						</Grid>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3} mb={4}>
						<Typography variant="h3" component="div" mb={3}>
							{t('common:title.departments')}
						</Typography>

						<Grid fontSize="0.8125em">
							<List component="nav" sx={{ p: 0 }}>
								{departmentsLinks.map((item, index) => {
									return (
										<Link key={index} href={item.link}>
											<ListItemButton sx={{ p: 0, mb: 0.5 }}>
												<ListItemText>{t(item.title)}</ListItemText>
											</ListItemButton>
										</Link>
									)
								})}
							</List>
						</Grid>
					</Grid>
				</Grid>

				<Grid container justifyContent="center" alignItems="center" fontFamily={PoppinsFont.style.fontFamily} fontSize={14}>
					{t('common:content.deline')}
					<Grid mx={0.5}>&copy;</Grid>
					{new Date().getFullYear()}
					<Grid mx={0.5}>|</Grid>

					<Link href="/articles/privacy-policy">
						<Button
							sx={{
								py: 0,
								fontFamily: PoppinsFont.style.fontFamily,
								textTransform: 'inherit',
								color: '#d70000',
								borderRadius: 1
							}}
						>
							{t('links:privacyPolicy')}
						</Button>
					</Link>
				</Grid>
			</Container>
		</Grid>
	)
}

export default FooterOrganism
